<template>
  <ListFrame
    :getPageFn="loadData"
    :immediateInit="false"
    :viewConf="{
      showReorder: false,
      shelfOperateAble: onlyRead?false:{
        offshelfFn,
        onshelfFn: false,
        upValue: 1,
        needOffReason: false,
      },
    }"
    :customedParams="query"
  >
    <template slot="conditions" slot-scope="{ requestFn }">
      <ul class="toolbar">
        <li>
          等级：
          <Input
            class="input"
            clearable
            v-model="query.contentLevel"
            placeholder="输入等级，如：1或1-10"
          />
        </li>
        <li>
          编号：
          <Input
            class="input"
            clearable
            v-model="query.id"
            placeholder="输入编号"
          />
        </li>
        <li>
          <Button type="primary" @click="requestFn">搜索</Button>
        </li>
      </ul>
    </template>
    <template slot-scope="{ item, requestFn }">
      <h2>{{ item.title }}</h2>
      <div class="level">等级：{{ item.contentLevel }}</div>
      <p class="datetime">提交时间：{{ item.publishTime | dateFormat }}</p>      
      <router-link :to="'/engArticle/' + item.id" target="_blank" style="margin-right:2em" v-if="!onlyRead">
        <Button class="btn" type="warning">编辑</Button>
      </router-link>
      <router-link :to="'/engArticleInfo/' + item.id">
        <Button class="btn" type="primary">详情</Button>
      </router-link>
    </template>
  </ListFrame>
</template>

<script>
import { ApiService } from "@/api/apiService";

export default {
  data() {
    return {
      query: {
        id: "",
        contentLevel: "",
      },
    };
  },
  computed:{
    onlyRead() {
      return this.$store.getters.getOnlyRead;
    }
  },
  methods: {
    loadData(params) {
      return ApiService.contentExt_queryIeng(params);
    },
    offshelfFn(list, reason) {
      list = JSON.parse(JSON.stringify(list));
      list.forEach((ele) => {
        ele.status = 2;
      });
      return ApiService.shelfChange(list);
    },
    onshelfFn(list) {},
  },
};
</script>

<style lang="less" scoped>
.toolbar {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  list-style: none;
  margin-bottom: 10px;

  > li {
    padding: 5px;
  }
}

.input {
  width: 180px;
  margin: 0;
}

.level {
  margin: 5px 0;
  color: #333;
}

.datetime {
  color: #999;
  font-size: 12px;
}

.btn {
  margin-top: 10px;
}
</style>
