var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ListFrame',{attrs:{"getPageFn":_vm.loadData,"immediateInit":false,"viewConf":{
    showReorder: false,
    shelfOperateAble: _vm.onlyRead?false:{
      offshelfFn: _vm.offshelfFn,
      onshelfFn: false,
      upValue: 1,
      needOffReason: false,
    },
  },"customedParams":_vm.query},scopedSlots:_vm._u([{key:"conditions",fn:function({ requestFn }){return [_c('ul',{staticClass:"toolbar"},[_c('li',[_vm._v(" 等级： "),_c('Input',{staticClass:"input",attrs:{"clearable":"","placeholder":"输入等级，如：1或1-10"},model:{value:(_vm.query.contentLevel),callback:function ($$v) {_vm.$set(_vm.query, "contentLevel", $$v)},expression:"query.contentLevel"}})],1),_c('li',[_vm._v(" 编号： "),_c('Input',{staticClass:"input",attrs:{"clearable":"","placeholder":"输入编号"},model:{value:(_vm.query.id),callback:function ($$v) {_vm.$set(_vm.query, "id", $$v)},expression:"query.id"}})],1),_c('li',[_c('Button',{attrs:{"type":"primary"},on:{"click":requestFn}},[_vm._v("搜索")])],1)])]}},{key:"default",fn:function({ item, requestFn }){return [_c('h2',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"level"},[_vm._v("等级："+_vm._s(item.contentLevel))]),_c('p',{staticClass:"datetime"},[_vm._v("提交时间："+_vm._s(_vm._f("dateFormat")(item.publishTime)))]),(!_vm.onlyRead)?_c('router-link',{staticStyle:{"margin-right":"2em"},attrs:{"to":'/engArticle/' + item.id,"target":"_blank"}},[_c('Button',{staticClass:"btn",attrs:{"type":"warning"}},[_vm._v("编辑")])],1):_vm._e(),_c('router-link',{attrs:{"to":'/engArticleInfo/' + item.id}},[_c('Button',{staticClass:"btn",attrs:{"type":"primary"}},[_vm._v("详情")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }